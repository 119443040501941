import Skill from "../shared/models/Skill";

export const JSON = new Skill("JSON", "json");
export const JS = new Skill("JavaScript", "js");
export const TS = new Skill("TypeScript", "ts");
export const JAVA = new Skill("Java", "java");
export const KOTLIN = new Skill("Kotlin", "kotlin");
export const PYTHON = new Skill("Python", "py");
export const SWIFT = new Skill("Swift", "swift");

export const LANGUAGES_SKILLS = [
  JAVA,
  KOTLIN,
  SWIFT,
  JS,
  TS,
  PYTHON,
];

export const NODE_JS = new Skill("NodeJS", "node");
export const NEST_JS = new Skill("NestJS", "nest");
export const EXPRESS = new Skill("ExpressJS", "express");
export const MONGO_DB = new Skill("MongoDB", "mongodb");
export const FIREBASE = new Skill("Firebase", "firebase");
export const SPRINGBOOT = new Skill("SpringBoot", "springboot");
export const JWT = new Skill("JWT", "jwt");
export const MYSQL = new Skill("MySQL", "mysql");
export const REDIS = new Skill("Redis", "redis");
export const NEXT_JS = new Skill("NextJS", "next");
export const OAUTH = new Skill("OAuth", "oauth");
export const REACT = new Skill("React", "react");
export const FASTAPI = new Skill("FastAPI", "fast");
export const SWIFTUI = new Skill("SwiftUI", "swift");

export const FRAMEWORKS_TOOLS = [
  NODE_JS,
  NEST_JS,
  FASTAPI,
  EXPRESS,
  MONGO_DB,
  FIREBASE,
  SPRINGBOOT,
  JWT,
  OAUTH,
  MYSQL,
  REDIS,
  NEXT_JS,
  REACT,
  SWIFTUI,
];

export const PLANNING = new Skill("Planning", "planning");
export const COLLABORATION = new Skill("Collaboration", "collaboration");
export const TIME_MANAGEMENT = new Skill("Time Management", "time-management");
export const PAIR_PROGRAMMING = new Skill("Pair Programming", "pc");

export const SOFT_SKILLS = [PLANNING, PAIR_PROGRAMMING, TIME_MANAGEMENT, COLLABORATION];

export const NOTION = new Skill("Notion", "notion");
export const TRELLO = new Skill("Trello", "trello");
export const GITHUB = new Skill("GitHub", "github");
export const POSTMAN = new Skill("Postman", "postman");
export const JIRA = new Skill("Jira", "jira");
export const Bitbucket = new Skill("Bitbucket", "bb");

export const TOOLS = [NOTION, TRELLO, GITHUB, POSTMAN, JIRA, Bitbucket];

export const SKILLS: { [key: string]: Skill[] } = {
  LANGUAGES: LANGUAGES_SKILLS,
  FRAMEWORKS_TOOLS: FRAMEWORKS_TOOLS,
  SOFT: SOFT_SKILLS,
  TOOLS: TOOLS,
};

export const LMS_SKILLS = [
  TS,
  NEST_JS,
  NEXT_JS,
  MONGO_DB,
  REDIS,
  JWT,
  NOTION,
  GITHUB,
  POSTMAN,
]

export const MORS_SKILLS = [
  TS,
  NODE_JS,
  EXPRESS,
  MONGO_DB,
  JWT,
  NOTION,
  GITHUB,
  POSTMAN,
]

export const STOCKFOLIO_SKILLS = [
  JS,
  NODE_JS,
  EXPRESS,
  MONGO_DB,
  JWT,
  NOTION,
  GITHUB,
  POSTMAN,
]

export const SAAS_SKILLS = [
  JS,
  NEXT_JS,
  NODE_JS,
  FIREBASE,
  GITHUB,
]

export const RECIPE_SKILLS = [
  KOTLIN,
  FIREBASE,
  GITHUB
]

export const EXPENSE_SKILLS = [
  KOTLIN,
  FIREBASE,
  GITHUB
];

export const PANTRY_SKILLS = [
  TS,
  NEXT_JS,
  NODE_JS,
  FIREBASE,
  GITHUB
];

export const PROMPT_SKILLS = [
  JS,
  NEXT_JS,
  NODE_JS,
  OAUTH,
  MONGO_DB,
  GITHUB,
];

export const POSTBUD_SKILLS = [
  SWIFTUI,
  FIREBASE,
  GITHUB,
];

